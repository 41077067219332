:root {
  --bg-color: #0f1118;
  --text-color: #e5e5e5;
  --accent-color: #64ffda;
  --secondary-color: #7928ca;
  --tertiary-color: #ff4d4d;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  line-height: 1.6;
  overflow-x: hidden;
}

header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

nav {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 1.5rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  background-color: rgba(15, 17, 24, 0.8);
  backdrop-filter: blur(8px);
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--accent-color);
  text-decoration: none;
}

.nav-links {
  display: flex;
  gap: 2rem;
}

.nav-links a {
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s;
  font-size: 1rem;
}

.nav-links a:hover {
  color: var(--accent-color);
}

.hero {
  text-align: center;
  padding: 0 1rem;
  max-width: 800px;
  margin: 0 auto;
}
/* Add to index.css */
.other-projects {
  background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.03));
  border: 1px solid rgba(255, 255, 255, 0.15);
  animation: float 8s ease-in-out infinite 3s !important;
}

.other-projects:before {
  background: linear-gradient(to right, var(--accent-color), var(--secondary-color), var(--tertiary-color));
}

/* Add new styles for AllProjects page */
.all-projects-container {
  min-height: 100vh;
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}
.hero h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
  background: linear-gradient(to right, var(--accent-color), var(--secondary-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.hero p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.btn {
  display: inline-block;
  padding: 0.8rem 1.8rem;
  background-color: transparent;
  border: 2px solid var(--accent-color);
  color: var(--accent-color);
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
}

.btn:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: var(--accent-color);
  transition: all 0.3s;
  z-index: -1;
}

.btn:hover {
  color: var(--bg-color);
}

.btn:hover:before {
  left: 0;
}

.projects-container {
  min-height: 100vh;
  padding: 5rem 1rem;
  position: relative;
  overflow: hidden;
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: var(--accent-color);
}

.projects {
  position: relative;
  height: 800px; /* Increased height to accommodate external icons */
  overflow: visible;
}

.project {
  position: absolute;
  width: 320px;
  height: 220px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  padding: 1.5rem;
  cursor: pointer;
  transition: transform 0.5s, box-shadow 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-icon-container {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
}

.project-icon-wrapper {
  width: 70px;
  height: 70px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
}

.project-icon-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.project-icon-wrapper:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

/* Thin connecting line */
.project-icon-container::before {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  width: 2px;
  height: 10px;
  background: linear-gradient(
    to bottom, 
    rgba(255, 255, 255, 0.2), 
    rgba(255, 255, 255, 0.1)
  );
  transform: translateX(-50%);
  z-index: 1;
}

.project:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.project:nth-child(1) {
  top: 50px;
  left: 10%;
}

.project:nth-child(2) {
  top: 150px;
  left: 40%;
}

.project:nth-child(3) {
  top: 100px;
  right: 10%;
}

.project:nth-child(4) {
  top: 300px;
  left: 20%;
}

.project:nth-child(5) {
  top: 350px;
  right: 20%;
}

.project:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
}

.project:nth-child(1):before {
  background-color: var(--accent-color);
}

.project:nth-child(2):before {
  background-color: var(--secondary-color);
}

.project:nth-child(3):before {
  background-color: var(--tertiary-color);
}

.project:nth-child(4):before {
  background-color: #FFD600;
}

.project:nth-child(5):before {
  background-color: #00C6FF;
}
.blog-container {
  min-height: 100vh;
  padding: 8rem 2rem 4rem;
  max-width: 800px;
  margin: 0 auto;
}

.blog-header {
  text-align: center;
  margin-bottom: 3rem;
}

.blog-header p {
  color: var(--text-color);
  opacity: 0.7;
  font-size: 1.1rem;
}

.blog-posts {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.blog-post-preview {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 1.5rem;
  transition: transform 0.3s, box-shadow 0.3s;
}

.blog-post-preview:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.blog-post-link {
  text-decoration: none;
  color: var(--text-color);
}

.blog-post-link h2 {
  color: var(--accent-color);
  margin-bottom: 0.5rem;
}

.blog-post-meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  opacity: 0.7;
}

.read-more {
  display: inline-block;
  margin-top: 0.5rem;
  color: var(--accent-color);
  font-weight: bold;
}

.blog-post-container {
  min-height: 100vh;
  padding: 8rem 2rem 4rem;
  max-width: 800px;
  margin: 0 auto;
}

.blog-post-header {
  margin-bottom: 2rem;
  text-align: center;
}

.blog-post-header h1 {
  color: var(--accent-color);
  margin-bottom: 1rem;
}

.blog-post-content p {
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .blog-container,
  .blog-post-container {
    padding: 6rem 1rem 2rem;
  }
}
.project h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.project p {
  font-size: 0.9rem;
  opacity: 0.8;
}

.tech-stack {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.tech {
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
}

.connections {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}

.about-container {
  min-height: 100vh;
  padding: 5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-content {
  display: flex;
  gap: 3rem;
  max-width: 1200px;
  margin: 0 auto;
}

.about-text {
  flex: 1;
}

.skills {
  flex: 1;
}

.about-text h3 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: var(--accent-color);
}

.about-text p {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.skills h3 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: var(--accent-color);
}

.skill-category {
  margin-bottom: 2rem;
}

.skill-category h4 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: var(--secondary-color);
}

.skill-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.skill-item {
  padding: 0.5rem 1rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 50px;
  font-size: 0.9rem;
}

.contact-container {
  min-height: 60vh;
  padding: 5rem 1rem;
  text-align: center;
}

.contact-info {
  max-width: 600px;
  margin: 0 auto;
}

.contact-info p {
  margin-bottom: 2rem;
  font-size: 1.1rem;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.social-link {
  color: var(--text-color);
  font-size: 1.5rem;
  transition: color 0.3s;
  text-decoration: none;
}

.social-link:hover {
  color: var(--accent-color);
}

footer {
  padding: 2rem;
  text-align: center;
  font-size: 0.9rem;
  opacity: 0.7;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
  nav {
      padding: 1rem;
  }
  
  .hero h1 {
      font-size: 2.5rem;
  }
  
  .hero p {
      font-size: 1.1rem;
  }
  
  .about-content {
      flex-direction: column;
  }
  
  .projects {
      height: auto;
  }
  
 /* Add these styles to your index.css file */

/* Project Detail Page Styles */
.project-detail-container {
  padding-top: 8rem;
}

/* Enhance project card hover state to indicate clickability */
.project {
  position: absolute;
  width: 300px;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  padding: 1.5rem;
  cursor: pointer;
  transition: transform 0.5s, box-shadow 0.5s, border-color 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.project:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.4);
  border-color: var(--accent-color);
}

.project:hover h3 {
  color: var(--accent-color);
}

.project:after {
  content: "View Details →";
  position: absolute;
  bottom: 10px;
  right: 15px;
  font-size: 0.8rem;
  opacity: 0;
  transition: opacity 0.3s;
  color: var(--accent-color);
}

.project:hover:after {
  opacity: 1;
}

/* Media queries for project detail page */
@media (max-width: 768px) {
  .project-detail-container {
    padding: 8rem 1rem 2rem 1rem;
  }
  
  .project-header {
    padding-left: 1rem !important;
  }
  
  .project-header h1 {
    font-size: 2rem !important;
  }
  
  .project-content div {
    grid-template-columns: 1fr !important;
  }
}
  
  .connections {
      display: none;
  }
}

/* Floating Animation */
@keyframes float {
  0% {
      transform: translateY(0px) rotate(0deg);
  }
  50% {
      transform: translateY(-20px) rotate(2deg);
  }
  100% {
      transform: translateY(0px) rotate(0deg);
  }
}
/* Experience Section Styles */
.experience-container {
  min-height: 100vh;
  padding: 5rem 1rem;
  position: relative;
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 0;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  background: var(--accent-color);
  left: 50%;
  margin-left: -2px;
  background: linear-gradient(to bottom, var(--accent-color), var(--secondary-color));
  border-radius: 4px;
}

.timeline-item {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
  margin-bottom: 60px;
}

.timeline-item:nth-child(odd) {
  left: 0;
}

.timeline-item:nth-child(even) {
  left: 50%;
}

.timeline-item::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  right: -10px;
  background-color: var(--bg-color);
  border: 4px solid var(--accent-color);
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.timeline-item:nth-child(even)::after {
  left: -10px;
}

.timeline-content {
  padding: 20px 25px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  position: relative;
  transition: all 0.3s ease;
}

.timeline-content:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  border-color: var(--accent-color);
}

.timeline-header {
  margin-bottom: 15px;
}

.timeline-header h3 {
  font-size: 1.5rem;
  margin-bottom: 5px;
  color: var(--accent-color);
}

.company {
  font-size: 1.2rem;
  font-weight: 500;
  display: block;
  margin-bottom: 5px;
}

.duration {
  display: inline-block;
  padding: 3px 10px;
  background-color: rgba(100, 255, 218, 0.1);
  border-radius: 20px;
  font-size: 0.9rem;
  color: var(--accent-color);
}

.description {
  margin: 15px 0;
  line-height: 1.6;
}

.tech-used {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 15px;
}

.tech-badge {
  padding: 4px 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  font-size: 0.8rem;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .timeline::before {
    left: 31px;
  }
  
  .timeline-item {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }
  
  .timeline-item:nth-child(even) {
    left: 0;
  }
  
  .timeline-item::after {
    left: 21px;
    right: auto;
  }
  
}

.project:nth-child(1) { animation: float 8s ease-in-out infinite; }
.project:nth-child(2) { animation: float 9s ease-in-out infinite 1s; }
.project:nth-child(3) { animation: float 7s ease-in-out infinite 0.5s; }
.project:nth-child(4) { animation: float 10s ease-in-out infinite 1.5s; }
.project:nth-child(5) { animation: float 6s ease-in-out infinite 2s; }